<template>
    <div>
        <app-table :id="table_id" :options="options" @action="triggerActions"></app-table>
    </div>
</template>
<script>
import { axiosPost, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import { UPDATE_PATIENT_ORDER_REFERRAL } from "../../../../Config/ApiUrl-CP";
import OrderListMixin from "../../../Mixins/OrderListMixin";
export default {
    name: "OrderList",
    props: {
        props: {

        }
    },
    mixins: [HelperMixin, OrderListMixin],
    data() {
        return {
            table_id: 'user-customer-order-table',
        };
    },
    methods: {
        triggerActions(row, action, active) {
            console.log(action.title);
            if (action.title === this.$t('Update Referral')) {
                this.updateReferral(row.id);
            }
        },
        updateReferral(orderid) {
            axiosPost(UPDATE_PATIENT_ORDER_REFERRAL+'/'+orderid).then(response => {
                if(response.data.status) {
                    this.toastAndReload(response.data.msg);
                } else {
                    this.$toastr.e(response.data.msg)
                }
                setTimeout(() => {
                    window.location.href = urlGenerator(`customer/details/${this.props[0]}?tab=Orders`);
                }, 1000);
            }).catch(error => {
                console.error(error);
            });
        },
    },
}
</script>