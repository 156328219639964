<template>
    <div>
        <div >
            <a class="order_count" @click.prevent="openModal()" href="javascript:void(0)">{{ (extraval == 'new')?this.getNewOrderCount(value):this.getModOrderCount(value) }}</a>
        </div>

        <tech-order-details
            v-if="isTechOrderDetailsModalActive"
            :row-data="rowData"
            :extraval="extraval"
            :row-single="value"
            :value="true"
            @modal-close="closeModal"
        />

    </div>
</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import {numberWithCurrencySymbol} from "../../../Helper/Helper";

export default {
    name: "techOrderCount",
    mixins: [FormHelperMixins],
    props: ['rowData', 'tableId', 'value','extraval'],
    data() {
        
        return {
            numberWithCurrencySymbol,
            isTechOrderDetailsModalActive: false,
            
        }
    },
    methods: {
        getNewOrderCount(data){
            let count = 0;
            data.forEach((v,i) => {
                if(v.new_order != null) count++
            });
            return count;
        },
        getModOrderCount(data){
            let count = 0;
            data.forEach((v,i) => {
                if(v.mod_order != null) count++
            });
            return count;
        },
        closeModal() {
            this.isTechOrderDetailsModalActive = false;
            this.$emit('modal-close');
        },
        openModal() {
            this.isTechOrderDetailsModalActive = true;
        }

    },
}
</script>
<style>
.datatable table .table-expanded-column button svg{
    display: none;
}
.datatable table.expandTblClass tbody tr td.datatable-td {
 padding: 1.5rem 1rem !important;
}
</style>