import DatatableHelperMixin from "../../../../common/Mixin/Global/DatatableHelperMixin";
import {ACTIVE_QUEUE_REPORT, ORDER_MAX_MIN_PRICE,SELECTABLE_PRACTITIONER, GENERATE_INVOICE_VIEW, GENERATE_PERFOMA_INVOICE_VIEW} from "../../../Config/ApiUrl-CP";
import { SELECTABLE_TECHNICIAN } from "../../../Config/ApiUrl-CPB";
import {axiosGet, urlGenerator} from "../../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../../Helper/SelectableOptions/SelectableStatusMixin";
import {mapGetters} from "vuex";
import {formatDateToLocal, numberWithCurrencySymbol} from "../../../Helper/Helper";

export default {
    mixins: [DatatableHelperMixin, SelectableStatusMixin],
    data() {
        return {
            filterHtml : '',
            options: {
                name: this.$t('cp_item_wise_report'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                numberWithCurrencySymbol,
                columns: [
                    {
                        title: this.$t('Sr No.'),
                        type: 'object',
                        key: 'sr_no',
                        modifier: (value) => value
                    },
                    {
                        title: this.$t('Order ID'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: (value) => value.invoice_number ? `<a href="${urlGenerator('order/details/'+value.id)}" class="redirectTo">${value.invoice_number}</a>` :'-',
                    },
                    {
                        title: this.$t('branch'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: value => value ? `<span>${value.branch_or_warehouse.name}</span>` : ''
                    },
                    {
                        title: this.$t('Practitioner'),
                        type: 'object',
                        sortable: false,
                        key: 'order',
                        modifier: (value) => (value.created_by?.full_name)
                    },
                    {
                        title: this.$t('Patient'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: value => value ? `<a href="${urlGenerator('customer/view/'+value.customer.id)}" class="redirectTo">${value.customer.full_name}</a>` : ''
                    },
                    {
                        title: this.$t('Workflow'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: (value,item) => this.getWorkflowName(item)
                    },
                    {
                        title: this.$t('Order Date'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: value => value ? `<span>${formatDateToLocal(value.created_at)}</span>` : ''
                    },
                    {
                        title: this.$t('Assigned Date'),
                        type: 'custom-html',
                        key: 'assigned_date',
                        modifier: value => value ? `<span>${formatDateToLocal(value)}</span>` : '-'
                    },
                    {
                        title: this.$t('Active Date'),
                        type: 'custom-html',
                        key: 'active_date',
                        modifier: value => value ? `<span>${formatDateToLocal(value)}</span>` : '-'
                    },
                    {
                        title: this.$t('Item Number'),
                        type: 'custom-html',
                        key: 'item_number',
                        modifier: value => value ? `<span>${value}</span>` : '-'
                    },
                    {
                        title: this.$t('Order Item Name'),
                        type: 'custom-html',
                        key: 'orderproduct',
                        modifier: value => value?.variant?.name ? `<span>${value?.variant?.name}</span>` : '-'
                    },
                    {
                        title: this.$t('Target Date'),
                        type: 'custom-html',
                        key: 'orderproduct',
                        modifier: value => value?.order_product_delivery_details?.target_date ? `<span>${formatDateToLocal(value?.order_product_delivery_details?.target_date)}</span>` : '-'
                    },
                    {
                        title: this.$t('Ready Date'),
                        type: 'custom-html',
                        key: 'orderproduct',
                        modifier: value => value?.order_product_delivery_details?.ready_date ? `<span>${formatDateToLocal(value?.order_product_delivery_details?.ready_date)}</span>` : '-'
                    },
                    {
                        title: this.$t('Estimated hours'),
                        type: 'custom-html',
                        key: 'estimated_hours',
                        modifier: value => value ? `<span>${value}</span>` : '-'
                    },
                    {
                        title: this.$t('Order Type'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: value => (value?.parent_orderid > 0)?'Mod':'New'
                    },
                    // {
                    //     title: this.$t('Queue Status'),
                    //     type: 'custom-html',
                    //     key: 'queue_status',
                    //     modifier: value => value?.translated_name ? value?.translated_name : '-'
                    // },
                    {
                        title: this.$t('Work Status'),
                        type: 'custom-html',
                        key: 'work_status',
                        modifier: (value) => {
                            if(value?.translated_name) {
                                let colorCls = '';
                                if(value.id == 81){
                                    colorCls = 'workActive'
                                } else if(value.id == 76) {
                                    colorCls = 'workCompleted'
                                } else if(value.id == 74) {
                                    colorCls = 'workInactive'
                                } else if(value.id == 82) {
                                    colorCls = 'workCancel'
                                } else if(value.id == 75) {
                                    colorCls = 'workProgress'
                                }
                                return `<span class="${colorCls}">${value?.translated_name}</span>`
                            } else {
                                return '-'
                            }
                        } 
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'order',
                        isVisible: true
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('Edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-queue-action-modal',
                        modalId: 'app-modal',
                        modifier: () => true
                    },
                ],
                filters: [
                    // {
                    //     title: this.$t('issue_date'),
                    //     type: "range-picker",
                    //     key: "date",
                    //     option: ["today", "thisMonth", "thisWeek", "lastMonth", "last7Days", "thisYear", "lastYear"]
                    // },
                    {
                        title: "Technician",
                        type: "drop-down-filter",
                        key: "technician",
                        option: [],
                    },
                    {
                        title: "Practitioner",
                        type: "drop-down-filter",
                        key: "practitioners",
                        option: [],
                    },
                    {
                        title: this.$t('Work Status'),
                        type: 'search-and-select-filter',
                        key: 'work_status',
                        settings: {
                            url: urlGenerator("app/selectable-statuses"),
                            modifire: (v) => {
                                return {id: v.id, name: v.translated_name}
                            },
                            params: {
                                type: 'workStatus',
                            },
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'name'
                    },
                    // {
                    //     title: this.$t('Referral'),
                    //     type: 'checkbox',
                    //     key: 'referral',
                    //     option: [
                    //         { id: 'NA', value: this.$t('NA') },
                    //         { id: 'Yes', value: this.$t('Yes') },
                    //         { id: 'No', value: this.$t('No') },
                    //     ],
                    // },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
            orderMaxMinPriceUrl: ORDER_MAX_MIN_PRICE
        }
    },
    methods: {
        getWorkflowName(item) {
            var name = ''
            if(item.user_type == 0) {
                name = item.technician.technician_name;
            } else {
                name = item.technicianuser.full_name;
            }
            return name
        },
        shouldDisplayCustomOrders(item) {
            const allowedCategoryIds = [1, 2, 3, 4, 5];
            return item.variant.product.main_category == 3 && allowedCategoryIds.includes(item.variant.product.category_id);
        },
        getMaxMinOrderPrice() {
            axiosGet(this.orderMaxMinPriceUrl).then((res) => {
                this.options?.filters.find(({key}) => {
                    if (key === 'range_filter') {
                        Object.assign(this.options.filters[3], res.data)
                    }
                })
            })
        },
        getInvoiceIdFilter() {
            this.options.filters.push({
                title: this.$t('invoice_id'),
                type: 'search-and-select-filter',
                key: 'invoice_number',
                settings: {
                    url: urlGenerator('app/selectable-invoice'),
                    modifire: ({id, invoice_number: value}) => ({id, value}),
                    params: {
                        branch_or_warehouse_id: this.getBranchOrWarehouseId,
                    },
                    per_page: 10,
                    queryName: 'search',
                    loader: 'app-pre-loader'
                }
            })
        },
        updateMinMaxPriceUrl() {
            this.orderMaxMinPriceUrl = `${ORDER_MAX_MIN_PRICE}?branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
        },
        practitionerList() {
            axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
                let name = this.options.filters.find(element => element.key === 'practitioners');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.full_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
        technicianList() {
            let filter = this.options.filters.find(element => element.key === 'technician');

            axiosGet(SELECTABLE_TECHNICIAN).then((response) => {
                if (filter) {
                    const technicianOptions = response.data.data.map(item => {
                        return {
                            id: item.id + '-T',
                            value: item.technician_name
                        };
                    });

                    filter.option = (filter.option || []).concat(technicianOptions);
                }
            }).catch((error) => {
                this.$toastr.e(error);
            });

            axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
                if (filter) {
                    const practitionerOptions = response.data.data.map(item => {
                        return {
                            id: item.id + '-P',
                            value: item.full_name
                        };
                    });

                    filter.option = (filter.option || []).concat(practitionerOptions);
                }
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
        getOrderStatus() {
            axiosGet("app/selectable-statuses?type=orderStatus").then((response) => {
                let name = this.options.filters.find(element => element.key === 'order_status_ids');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        name: name.translated_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
        getPaymentStatus() {
            axiosGet("app/selectable-statuses?type=paymentStatus").then((response) => {
                let name = this.options.filters.find(element => element.key === 'payment_status');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        name: name.translated_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },

    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted() {
        this.updateUrl(ACTIVE_QUEUE_REPORT, this.updateMinMaxPriceUrl);
        // this.getInvoiceIdFilter();
        this.getMaxMinOrderPrice();
        $(document).on('click','.redirectTo',function(){
            window.open($(this).attr('href'),'_blank');
        });
        const prac = axiosGet(SELECTABLE_PRACTITIONER).then((response) => {
            return response.data.data.map(name => {
                return {
                    id: name.id,
                    value: name.full_name
                }
            });
        }).catch((error) => {
            this.$toastr.e(error);
        });
        const orderStatusArr  = axiosGet("app/selectable-statuses?type=orderStatus").then((response) => {
            return response.data.data.map(name => {
                return {
                    id: name.id,
                    value: name.translated_name
                }
            });
        }).catch((error) => {
            this.$toastr.e(error);
        });
        const paymentStatusArr  = axiosGet("app/selectable-statuses?type=paymentStatus").then((response) => {
            return response.data.data.map(name => {
                return {
                    id: name.id,
                    value: name.translated_name
                }
            });
        }).catch((error) => {
            this.$toastr.e(error);
        });
        // this.$hub.$on('filter-change', (newFilterValues) => {
        //     let filterData = Object.fromEntries(Object.entries(newFilterValues).filter(([_, v]) => v !== ''));
        //     this.filterHtml = '';
        //     if(filterData?.date?.start) {
        //         this.filterHtml = '<span class="d-block">Sales Report from '+formatDateToLocal(filterData?.date.start,false,null,0,'DD MMM YYYY')+' to '+formatDateToLocal(filterData?.date.end,false,null,0,'DD MMM YYYY')+'</span>';
        //     }
        //     if(filterData?.practitioners) {
        //         prac.then((resp) => {
        //             let practArr = filterData?.practitioners.split(',');
        //             var transformedArray = practArr.map(id => {
        //                 var option = resp.find((opt) => {
        //                     if(opt.id == id) return opt;
        //                 });
        //                 return option ? option.value : undefined;
        //             });
        //             this.filterHtml += '<span class="d-block">Practitioner : '+transformedArray.join(',')+'</span>';
        //         });
        //     }
        //     if(filterData?.customers) {
        //         axiosGet('app/selectable-customers?selected='+filterData?.customers).then((response) => {
        //             const customerArr = response.data.data.map(name => {
        //                 return {
        //                     id: name.id,
        //                     value: name.full_name
        //                 }
        //             });
        //             this.filterHtml += '<span class="d-block">Patient : '+customerArr[0].value+'</span>';
        //         }).catch((error) => {
        //             this.$toastr.e(error);
        //         });
        //     }
        //     if(filterData?.range_filter) {
        //         this.filterHtml += '<span class="d-block">Price Range From '+numberWithCurrencySymbol(filterData?.range_filter?.min)+' To '+numberWithCurrencySymbol(filterData?.range_filter?.max)+'</span>';
        //     }
        //     if(filterData?.order_status_ids) {
        //         orderStatusArr.then((resp) => {
        //             let practArr = filterData?.order_status_ids.split(',');
        //             var transformedArray = practArr.map(id => {
        //                 var option = resp.find((opt) => {
        //                     if(opt.id == id) return opt;
        //                 });
        //                 return option ? option.value : undefined;
        //             });
        //             this.filterHtml += '<span class="d-block">Order Status : '+transformedArray.join(',')+'</span>';
        //         });
        //     }
        //     if(filterData?.payment_status) {
        //         paymentStatusArr.then((resp) => {
        //             let practArr = filterData?.payment_status.split(',');
        //             var transformedArray = practArr.map(id => {
        //                 var option = resp.find((opt) => {
        //                     if(opt.id == id) return opt;
        //                 });
        //                 return option ? option.value : undefined;
        //             });
        //             this.filterHtml += '<span class="d-block">Payment Status : '+transformedArray.join(',')+'</span>';
        //         });
        //     }
        // });
    },
    watch: {
        getBranchOrWarehouseId(new_id) {
            this.updateUrl(ACTIVE_QUEUE_REPORT, this.updateMinMaxPriceUrl);
            this.$hub.$emit(`reload-${this.table_id}`)
        },
    },
    created() {
        this.updateMinMaxPriceUrl();
        this.practitionerList();
        this.technicianList();
        this.getOrderStatus();
        this.getPaymentStatus();
    }
}