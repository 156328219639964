import { render, staticRenderFns } from "./CartDetails.vue?vue&type=template&id=673e96b9&scoped=true&"
import script from "./CartDetails.vue?vue&type=script&lang=js&"
export * from "./CartDetails.vue?vue&type=script&lang=js&"
import style0 from "./CartDetails.vue?vue&type=style&index=0&id=673e96b9&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673e96b9",
  null
  
)

export default component.exports