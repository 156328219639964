<template>
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-8">
                <app-breadcrumb :page-title="ucWords($t('order_details'))"
                    :button="{ label: $t('back_to_orders'), url: getAppUrl('/orders') }" />
            </div>
            <div class="col-sm-4">
                <div class="text-sm-right">
                </div>
            </div>
        </div>

        <div class="card card-with-shadow py-5 border-0">
            <div class="div container">
                <div class="row mt-2">
                    <div class="col-6 mb-4">
                        <span v-if="invoice.ref_orderid > 0" >Main Order Number - <a :href="urlGenerator('order/details/'+invoice.ref_orderid)">
                            {{ invoice.ref_order.invoice_number }}
                        </a></span>
                        <span class="d-block" v-if="invoice.ref_orderid > 0 && invoice.products.length > 0" >Original item being modified : <b> {{ invoice.products[0].variant.name  }} </b></span>
                    </div>
                    <div class="col-6 mb-4">
                        <button v-if="ordersAddEditItem && invoice.invoice_gen_number == null && invoice.perfoma_gen_number == null && (invoice.ref_orderid == 0 || (invoice.ref_orderid > 0 && invoice.order_products.length == 0))" class="btn btn-primary float-right" @click="openOrderActionModal" type="button">Add Item</button>
                        <!-- <button v-if="ordersEditDiscount" type="button" class="btn btn-primary float-right mr-2" @click="openDiscountActionModal(invoice)">Edit Discount</button> -->
                        <button v-if="ordersCreateMod && invoice.ref_orderid == 0" type="button" class="btn btn-primary float-right mr-2" @click="openOrderModActionModal(invoice)">Mod Order</button>
                    </div>
                    <div class="col-md-5 row">
                        <div class="col-md-6">{{ $t('date') }} :</div>
                        <div class="col-md-6">{{ formatDateTimeToLocal(invoice.created_at) }}</div>
                        <div class="col-md-6">{{ $t('Workshop') }} :</div>
                        <div class="col-md-6">
                            <template v-if="invoice.branch_or_warehouse">
                                {{ workshopName }}
                            </template>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-5 row">
                        <div class="col-md-6">{{ $t('Order number') }} :</div>
                        <div class="col-md-6">{{ invoice.invoice_number }}</div>
                        <div class="col-md-6">{{ $t('status') }} :</div>
                        <div class="col-md-6">{{ $t(invoice.status?.name) }}</div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-5 row">
                        <div class="col-md-6">{{ $t('invoice_to') }} :</div>
                        <div class="col-md-6"><a :href="urlGenerator('customer/view/'+invoice.customer?.id)">{{ invoice.customer?.full_name }}</a></div>
                        <div class="col-md-6">{{ $t('Payment Status') }} :</div>
                        <div class="col-md-6">{{ $t(invoice.payment_status?.name) }}</div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-5 row">
                        <div class="col-md-6">{{ $t('Practitioner') }} :</div>
                        <div class="col-md-6">{{ invoice?.created_by?.full_name }}</div>
                        <div class="col-md-6">{{ $t('Branch') }} :</div>
                        <div class="col-md-6">{{ invoice.branch_or_warehouse?.name }}</div>
                    </div>
                </div>

                <div class="mb-4 row no-gutters px-4 mt-5">
                    <div class="labels col-12 row border-bottom no-gutters">
                        <p class="text-muted col-md-1">#</p>
                        <p class="text-muted col-md-2">{{ $t('items') }}</p>
                        <p class="text-muted col-md-1">{{ $t('quantity') }}</p>
                        <p class="text-muted col-md-2">{{ $t('unit_price') }}</p>
                        <p class="text-muted col-md-1">{{ $t('unit_discount') }}</p>
                        <p class="text-muted col-md-2">{{ $t('total') }}</p>
                        <p class="text-muted col-md-1">{{ $t('CFU') }}</p>
                        <p class="text-muted col-md-1 text-right">{{ $t('Customize') }}</p>
                        <p class="text-muted col-md-1 text-right">{{ $t('Action') }}</p>
                    </div>

                    <div class="values row no-gutters align-items-baseline justify-content-between col-md-12 border-bottom py-3"
                        v-for="(item, index) in invoice.order_products" :key="item.invoice_number">
                        <p class="col-md-1"> {{ index + 1 }} </p>
                        <div class="d-flex align-items-center col-md-2">
                            <div>
                                <template v-if="item.variant">
                                    <a> {{ item.variant.name }} </a>
                                    <small class="d-block text-muted">{{ item.variant.upc }}</small>
                                    <small class="d-block text-muted">{{ item.custom_desc }}</small>
                                </template>
                            </div>
                        </div>
                        <p class="col-md-1">
                            {{ item.quantity }}
                            <template v-if="item.variant">
                                <template v-if="item.variant.product">
                                    <template v-if="item.variant.product.unit">
                                        ({{ item.variant.product.unit.name }})
                                    </template>
                                </template>
                            </template>
                        </p>
                        <p class="col-md-2"> {{ numberWithCurrencySymbol(item.price) }} </p>
                        <p class="col-md-1"> {{ item.discount_type === 'percentage' ? (item.discount_value + '%') :
                            numberWithCurrencySymbol(item.discount_amount) }} </p>
                        <p class="col-md-2"> {{ numberWithCurrencySymbol(item.sub_total) }} </p>
                        <div v-if="shouldDisplayCustomOrders(item)" class="col-md-1">
                            <!-- <input type="checkbox" v-model="checkedItems[item.id]" @change="handleCheckboxChange(item.id)"
                                :checked="isChecked(item)" /> -->
                                {{ (item.stock_deduction_type == 1)?'Branch':'Workshop' }}
                            
                        </div>
                        <div v-else class="col-md-1"></div>
                        <p class="col-md-1">
                            <template v-if="item.variant">
                                <template v-if="item.variant.custom_insoles">
                                    <template v-if="shouldDisplayCustomOrders(item)">
                                        <div>
                                            <!-- <app-default-button :class="'btn-sm float-right'" @click="openModal(item)" :title="$fieldTitle('+', item.custom_garments_customization ? 'Edit' : 'Add', true)" /> -->
                                            <app-default-button v-if="ordersAddEditCustomization" :class="'btn-sm float-right'" @click="openModal(item)" :title="$fieldTitle('Go')" />
                                            <!-- <a class="invoice_number" @click="openModal()">Add Data</a><br> -->
                                            <app-custom-garments-customization-modal v-if="isCustomGarmentsModalActive && item.variant.product.category_id == 5"
                                                v-model="isCustomGarmentsModalActive" :selected-url="customOrdersUrl"
                                                @close="isCustomGarmentsModalActive = false"
                                                :order-product-id="selectedOrderProductId" :order-id="selectedOrderId"
                                                :branch-or-warehouse-id="invoice.branch_or_warehouse_id" />
                                            <app-custom-footware-customization-modal v-if="isCustomFootwareModalActive && item.variant.product.category_id == 3"
                                                v-model="isCustomFootwareModalActive" :selected-url="customOrdersUrl"
                                                @close="isCustomFootwareModalActive = false"
                                                :order-product-id="selectedOrderProductId" :order-id="selectedOrderId"
                                                :branch-or-warehouse-id="invoice.branch_or_warehouse_id" :item="item"/>
                                            <app-custom-orthoses-customization-modal v-if="isCustomOrthosesModalActive && item.variant.product.category_id == 1"
                                                v-model="isCustomOrthosesModalActive" :selected-url="customOrdersUrl"
                                                @close="isCustomOrthosesModalActive = false"
                                                :order-product-id="selectedOrderProductId" :order-id="selectedOrderId"
                                                :branch-or-warehouse-id="invoice.branch_or_warehouse_id" />
                                            <app-custom-prosthetics-customization-modal
                                                v-if="isCustomProstheticsModalActive && item.variant.product.category_id == 4"
                                                v-model="isCustomProstheticsModalActive" :selected-url="customOrdersUrl"
                                                @close="isCustomProstheticsModalActive = false"
                                                :order-product-id="selectedOrderProductId" :order-id="selectedOrderId"
                                                :branch-or-warehouse-id="invoice.branch_or_warehouse_id" />
                                            <app-custom-insoles-customization-modal v-if="isCustomInsolesModalActive && item.variant.product.category_id == 2"
                                                v-model="isCustomInsolesModalActive" :selected-url="customOrdersUrl"
                                                @close="isCustomInsolesModalActive = false"
                                                :order-product-id="selectedOrderProductId" :order-id="selectedOrderId"
                                                :branch-or-warehouse-id="invoice.branch_or_warehouse_id" :item="item" :invoice="invoice" />
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </p>
                        <p class="col-md-1">
                            <button type="button" v-if="ordersAddEditItem" class="btn btn-primary btn-sm float-right" @click="openOrderActionModal(item)" title="Edit" ><i class="far fa-edit"></i></button>
                            <button type="button" v-if="ordersAddEditTechnician && shouldDisplayCustomOrders(item)" class="btn btn-primary btn-sm float-right mr-1" @click="openTechnicianActionModal(item,[],(index+1))" title="Add Workflow" ><i class="fa fa-user-plus"></i></button>
                        </p>
                        <table width="80%" style="margin:0 auto;" class="mt-3" v-if="ordersAddEditTechnician">
                            <thead>
                                <tr v-if="item.order_product_technicians.length > 0">
                                    <th>User Name</th>
                                    <th>Estimated Hours</th>
                                    <th>Item Number</th>
                                    <th>Work status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="item.order_product_technicians" v-for="(techItem,techIndex) in item.order_product_technicians" :key="techItem.id">
                                    <td v-if="techItem.user_type == '0'">{{ techItem.technician.technician_name }}</td>
                                    <td v-if="techItem.user_type == '1'">{{ techItem.technicianuser.full_name }}</td>
                                    <td>{{ techItem.estimated_hours }}</td>
                                    <td>{{ techItem.item_number }}</td>
                                    <td>{{ techItem.work_status.translated_name }}</td>
                                    <td>
                                        <button type="button" v-if="ordersAddEditTechnician" @click="openTechnicianActionModal(item,techItem,(index+1))" class="btn btn-primary btn-sm"><i class="far fa-edit"></i></button>
                                        <button type="button" v-if="ordersAddEditTechnician" @click="deleteTechnician(techItem.id)" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-12 text-center" v-if="item.mod_order_items.length > 0 && item.mod_order_items[0].order_products.length > 0" style="font-size: 18px;"><b>Modification Orders</b></div>
                        <table v-if="item.mod_order_items.length > 0 && item.mod_order_items[0].order_products.length > 0" class="table text-center">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Mod Order No.</th>
                                    <th>Warranty End</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(moditem,modIndex) in item.mod_order_items">
                                    <tr v-for="(item1,indx1) in moditem.order_products" :key="item1.id">
                                        <td> {{ formatDateToLocal(moditem.created_at) }} </td>
                                        <td> <a :href="urlGenerator('order/details/'+moditem.id)">{{ moditem.invoice_number }}</a> </td>
                                        <td> {{ formatDateToLocal(moditem.invoice_gen_date,false,null,((item1.extra_warranty_days > 0)?item1.extra_warranty_days:(item1.variant?.product?.warranty_duration??0))) }} </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-7 row mt-4">
                        <div class="col-md-12" v-if="invoice.due_history">
                            <h5>{{ $t('Due Payment Info') }} :</h5>
                            <table width="100%" style="font-size: 12px;">
                                <thead>
                                    <tr>
                                        <th width="25%">Date</th>
                                        <th width="15%">Amount</th>
                                        <th width="20%">Payment Mode</th>
                                        <th width="30%">Note</th>
                                        <th width="10%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in invoice.due_history" :key="item.id">
                                        <td>{{ formatDateTimeToLocal(item.updated_at)??formatDateTimeToLocal(item.created_at) }}</td>
                                        <td>{{ item.paid_amount }}</td>
                                        <td>{{ item.payment_mode.item_label }}</td>
                                        <td>{{ item.payment_note }}</td>
                                        <td><button type="button" v-if="paymentsAddEdit" class="btn btn-sm btn-link text-primary" @click="openPaymentActionModal(item.id)"><i class="fa fa-edit"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12 mt-3" v-if="invoice.reversed_history?.length > 0">
                            <h5>{{ $t('Reversed Payment Info') }} :</h5>
                            <table width="100%" style="font-size: 12px;">
                                <thead>
                                    <tr>
                                        <th width="25%">Date</th>
                                        <th width="15%">Amount</th>
                                        <th width="20%">Payment Mode</th>
                                        <th width="30%">Note</th>
                                        <th width="10%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in invoice.reversed_history" :key="item.id">
                                        <td>{{ formatDateTimeToLocal(item.reversed_payment_date) }}</td>
                                        <td>{{ item.paid_amount }}</td>
                                        <td>{{ item.payment_mode.item_label }}</td>
                                        <td>{{ item.payment_note }}</td>
                                        <td><button type="button" v-if="paymentsAddEditReversed" class="btn btn-sm btn-link text-primary" @click="openPaymentActionModal(item.id)"><i class="fa fa-edit"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-5 text-right row  mt-4">
                        <div class="col-md-6">
                            {{ $t('sub_total') }}
                        </div>
                        <div class="col-md-6 text-right">
                            {{ numberWithCurrencySymbol(getSubTotal(invoice)) }}
                        </div>
                        <div class="col-md-6">
                            {{ $t('Discount') }} <span v-if="invoice.discount_type === 'percentage' && invoice.discount != 0">[
                                -{{ invoice.discount }} %]</span>
                        </div>
                        <div class="col-md-6 text-right">
                            {{ numberWithCurrencySymbol(getDiscountValue(invoice)) }}
                        </div>
                        <div class="col-md-6">
                            {{ $t('tax') }} <span v-if="invoice?.tax?.percentage">[ +{{ invoice.tax.percentage }} %]</span>
                        </div>
                        <div class="col-md-6 text-right">
                            {{ numberWithCurrencySymbol(getTotalTax(invoice)) }}
                        </div>
                        <div class="col-md-6 border-top py-2">
                            {{ $t('invoice_total') }}
                        </div>
                        <div class="col-md-6 text-right border-top  py-2">
                            {{ numberWithCurrencySymbol(invoice.grand_total) }}
                        </div>
                        <div class="col-md-6 text-right border-top">
                            {{ $t('paid') }}
                        </div>
                        <div class="col-md-6 text-right border-top">
                            {{ numberWithCurrencySymbol(invoice.paid_amount) }}
                        </div>
                        <!-- <div class="col-md-6">
                            {{ $t('change') }}
                        </div>
                        <div class="col-md-6 text-right">
                            {{ numberWithCurrencySymbol(invoice.change_return) }}
                        </div> -->
                        <div class="col-md-6">
                            {{ $t('Balance') }}
                        </div>
                        <div class="col-md-6 text-right">
                            {{ numberWithCurrencySymbol(invoice.due_amount) }}
                        </div>
                    </div>
                </div>

                <div class="row mt-4 justify-content-between">
                    <div class="col-12 text-center my-3">
                        <h3>Status & Warranty for Custom Products</h3>
                    </div>
                    <p class="text-muted col-md-1">#</p>
                    <p class="text-muted col-md-4">Items</p>
                    <p class="text-muted col-md-2" v-if="ordersEdit" >Delivery status</p>
                    <p class="text-muted col-md-2">Warranty End</p>
                    <p class="text-muted col-md-2">Target Date</p>
                    <p class="text-muted col-md-1">Action</p>
                </div>
                <div class="row border-bottom py-3 no-gutters align-items-baseline justify-content-between"
                    v-for="(item, index) in rowData" :key="item.invoice_number" v-if="item?.variant?.product?.main_category != 4">
                    <div class="col-md-1">{{ index + 1 }}</div>
                    <div class="col-md-4">
                        <template v-if="item.variant">
                            <a> {{ item.variant.name }} </a>
                            <small class="d-block text-muted">{{ item.variant.upc }}</small>
                        </template>
                    </div>
                    <div class="col-md-2" v-if="ordersEdit">
                        <app-delivery-status :rowData="item" :table-id="tableId" :value="value" />
                    </div>
                    <div class="col-md-2 px-3">
                        <template v-if="invoice.invoice_gen_date" >
                            <span>{{ formatDateToLocal(invoice.invoice_gen_date,false,null,(item.extra_warranty_days > 0)?item.extra_warranty_days:(item?.variant?.product?.warranty_duration??0)) }}
                            <!-- ({{ item?.variant?.product?.warranty_duration??0 }}) -->
                            </span>
                            <small v-if="(item.extra_warranty_days > 0)?item.extra_warranty_days:(item?.variant?.product?.warranty_duration??0) > 0" class="d-block text-success"> Under Warranty </small>
                            <small v-else class="d-block text-danger"> Out Of Warranty </small>
                        </template>
                    </div>
                    <div class="col-md-2 px-4">
                        <span>{{ (item.order_product_delivery_details?.target_date)?formatDateToLocal(item.order_product_delivery_details?.target_date):'' }}</span>
                    </div>
                    <div class="col-md-1 px-3">
                        <button type="button" class="btn btn-primary btn-sm" v-if="ordersAddEditDeliveryDetails" title="Edit Delivery Details" @click="openDeliveryActionModal(item)"><i class="fa fa-truck"></i></button>
                    </div>
                </div>

                <table class="table table-hover mt-4">
                    <tbody>
                        <tr v-if="ordersEdit">
                            <!-- <app-delivery-status :rowData="rowData" :table-id="tableId" :value="value" /> -->

                            <th>Payment Status:</th>
                            <td colspan="4"><app-payment-status :rowData="invoice" :table-id="tableId" :value="value" /></td>
                        </tr>
                        <tr v-if="ordersEdit">
                            <th>Order Status:</th>
                            <td colspan="4"><app-orders-status :rowData="invoice" :table-id="tableId" :value="value" /></td>
                        </tr>
                        <tr v-if="cpPaymentsView && invoice.branch_or_warehouse?.branch_type == 'partner'">
                            <th>CP Payment Status:</th>
                            <td> <div v-if="invoice.cp_payment_status">{{ invoice?.cp_payment_status?.translated_name??'-' }}</div> </td>
                            <td> Inv. No - {{ invoice.cp_invoice_no??'' }} </td>
                            <td> Note - {{ invoice.cp_payment_note??'' }} </td>
                            <td> <button type="button" v-if="cpPaymentsEdit" class="btn btn-primary btn-sm" title="Edit Delivery Details" @click="openCPpaymentStatusActionModal()"><i class="fa fa-edit"></i></button> </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <div class="row">
                        <div class="col-12 text-center my-3">
                            <h3>Order Details</h3>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label for="extraFormData_referralStatus" class="form-label">Referral Status:</label>
                                        <app-input type="select" :list="referralOptions" list-value-field="name"
                                                v-model="extraFormData.referralStatus" :required="false" :placeholder="$placeholder('Referral Status')" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <h3>Referral note</h3>
                                    <div class="mb-3">
                                        <textarea v-model="extraFormData.referralNote" id="" cols="30" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center my-3">
                            <h3>Customer Items Retained</h3>
                        </div>
                        <div class="col-12">
                            <div class="mb-3">
                                <textarea v-model="extraFormData.customer_items_retained" id="" cols="30" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center my-3">
                            <h3>For Workshop Use</h3>
                        </div>
                        
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 1</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file1"
                                        @change="handleImagesUpload('workshopfiles', 'file1')" />
                                        <img :src="workshopfiles.file1Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file1Url && workshopfiles.file1Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file1Url && !workshopfiles.file1Url">
                                        {{ workshopfiles.file1Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file1Url && workshopfiles.file1 && workshopfiles.file1 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file1)"  width="200px" alt="" />
                                        </div>
                                        <div class="row col-md-6 mb-2">
                                            {{ getAttachmentFileName(workshopfiles.file1) }}
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file1)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file1)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file1')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 2</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file2"
                                        @change="handleImagesUpload('workshopfiles', 'file2')" />
                                        <img :src="workshopfiles.file2Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file2Url && workshopfiles.file2Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file2Url && !workshopfiles.file2Url">
                                        {{ workshopfiles.file2Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file2Url && workshopfiles.file2 && workshopfiles.file2 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file2)"  width="200px" alt="" />
                                        </div>
                                        <div class="row col-md-6 mb-2">
                                            {{ getAttachmentFileName(workshopfiles.file2) }}
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file2)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file2)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file2')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 3</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file3"
                                        @change="handleImagesUpload('workshopfiles', 'file3')" />
                                        <img :src="workshopfiles.file3Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file3Url && workshopfiles.file3Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file3Url && !workshopfiles.file3Url">
                                        {{ workshopfiles.file3Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file3Url && workshopfiles.file3 && workshopfiles.file3 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file3)"  width="200px" alt="" />
                                        </div>
                                        <div class="row col-md-6 mb-2">
                                            {{ getAttachmentFileName(workshopfiles.file3) }}
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file3)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file3)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file3')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 4</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file4"
                                        @change="handleImagesUpload('workshopfiles', 'file4')" />
                                        <img :src="workshopfiles.file4Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file4Url && workshopfiles.file4Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file4Url && !workshopfiles.file4Url">
                                        {{ workshopfiles.file4Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file4Url && workshopfiles.file4 && workshopfiles.file4 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file4)"  width="200px" alt="" />
                                        </div>
                                        <div class="row col-md-6 mb-2">
                                            {{ getAttachmentFileName(workshopfiles.file4) }}
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file4)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file4)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file4')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 5</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file5"
                                        @change="handleImagesUpload('workshopfiles', 'file5')" />
                                        <img :src="workshopfiles.file5Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file5Url && workshopfiles.file5Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file5Url && !workshopfiles.file5Url">
                                        {{ workshopfiles.file5Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file5Url && workshopfiles.file5 && workshopfiles.file5 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file5)"  width="200px" alt="" />
                                        </div>
                                        <div class="row col-md-6 mb-2">
                                            {{ getAttachmentFileName(workshopfiles.file5) }}
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file5)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file5)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file5')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 6</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file6"
                                        @change="handleImagesUpload('workshopfiles', 'file6')" />
                                        <img :src="workshopfiles.file6Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file6Url && workshopfiles.file6Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file6Url && !workshopfiles.file6Url">
                                        {{ workshopfiles.file6Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file6Url && workshopfiles.file6 && workshopfiles.file6 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file6)"  width="200px" alt="" />
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file6)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file6)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file6')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 7</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file7"
                                        @change="handleImagesUpload('workshopfiles', 'file7')" />
                                        <img :src="workshopfiles.file7Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file7Url && workshopfiles.file7Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file7Url && !workshopfiles.file7Url">
                                        {{ workshopfiles.file7Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file7Url && workshopfiles.file7 && workshopfiles.file7 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file7)"  width="200px" alt="" />
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file7)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file7)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file7')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row mb-4">
                                <div class="d-flex align-items-center col-md-3 mb-3">
                                    <label class="form-label">File 8</label>
                                </div>
                                <div class="col-md-9 mb-3">
                                    <div>
                                        <input type="file" class="form-control"
                                        accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                                        ref="workshopfiles-file8"
                                        @change="handleImagesUpload('workshopfiles', 'file8')" />
                                        <img :src="workshopfiles.file8Url" width="200px" alt="Uploaded Image"
                                        v-if="workshopfiles.file8Url && workshopfiles.file8Url" />
                                    </div>
                                    <div class="text-danger" v-if="workshopfiles.file8Url && !workshopfiles.file8Url">
                                        {{ workshopfiles.file8Url }}
                                    </div>
                                    <div v-if="!workshopfiles.file8Url && workshopfiles.file8 && workshopfiles.file8 != 'delete'">
                                        <div class="row col-md-6">
                                            <img :src="urlGenerator(workshopfiles.file8)"  width="200px" alt="" />
                                        </div>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file8)" target="_blank"><app-icon name="eye" /></a>
                                        <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(workshopfiles.file8)" download target="_blank"><app-icon name="download" /></a>
                                        <a class="btn btn-danger text-white cursor-pointer btn-sm" @click="() => deleteImage('file8')"><app-icon name="trash-2" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-3">
                            <div class="mb-3">
                                <app-form-group :label="$t('Locker IN')" v-model="extraFormData.lockerin" :placeholder="$placeholder($t('Locker IN'))">
                                </app-form-group>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="mb-3">
                                <app-form-group :label="$t('Locker OUT')" v-model="extraFormData.lockerout" :placeholder="$placeholder($t('Locker OUT'))">
                                </app-form-group>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-3">
                                <app-form-group
                                    v-model="extraFormData.workshop_note"
                                    type="textarea"
                                    class="mb-2"
                                    :label="$t('Workshop Notes')"
                                    :required="false"
                                />
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" v-if="ordersEdit" type="button" @click.prevent="submitData()">
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>
        <app-order-action-modal v-if="isOrdersActionModalActive" :edititeminfo="iteminfo" :orderinfo="invoice" :order-id="orderId" :value="true" @modal-close="closeModal" />
        <app-payment-action-modal v-if="isPaymentActionModalActive" :value="true" :edititeminfo="iteminfo" :orderinfo="invoice" :order-id="orderId" :paymentId="paymentId" @modal-close="closeModal" />
        <app-discount-action-modal v-if="isDiscountActionModalActive" :value="true" :edititeminfo="iteminfo" :order-id="orderId" @modal-close="closeModal" />
        <app-technician-action-modal v-if="isTechnicianActionModalActive" :edititeminfo="iteminfo" :itemidx="itemIdx" :edittechinfo="techinfo" :order-id="orderId" :value="true" @modal-close="closeModal" />
        <app-delivery-action-modal v-if="isDeliveryActionModalActive" :edititeminfo="iteminfo" :edittechinfo="techinfo" :order-id="orderId" :value="true" @modal-close="closeModal" />
        <app-modorder-action-modal v-if="isModOrderActionModalActive" :edititeminfo="iteminfo" :edittechinfo="techinfo" :order-id="orderId" :value="true" @modal-close="closeModal" />
        <app-cppayment-action-modal v-if="isCPpaymentStatusActionModalActive" :edititeminfo="invoice" :order-id="orderId" :value="true" @modal-close="closeModal" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import FormHelperMixins from "../../../../../../common/Mixin/Global/FormHelperMixins";
import CoreLibrary from "../../../../../../core/helpers/CoreLibrary";
import { DUE_PAYMENT_INFO,SELECTABLE_DROPDOWN, SAVE_ORDER_DATA, DUE_PAYMENT_RECEIVE, INVOICE_VIEW_ORDER, CUSTOM_INSOLES_CUSTOMIZATION, CUSTOM_GARMENTS_CUSTOMIZATION, CHANGE_WAREHOUSE_STATUS, DELETE_ORDER_TECHNICIAN } from '../../../../../Config/ApiUrl-CP';
import { SELECTABLE_TECHNICIAN } from '../../../../../Config/ApiUrl-CPB';
import { axiosGet, axiosPost, axiosPatch, urlGenerator } from '../../../../../../common/Helper/AxiosHelper';
import { ucWords } from "../../../../../../common/Helper/Support/TextHelper";
import { formatDateTimeToLocal, numberWithCurrencySymbol, formatDateToLocal } from "../../../../../Helper/Helper";
import { purify } from "../../../../../Helper/Purifier/HTMLPurifyHelper";
import PrintAria from "../../../../../../common/Components/Helper/PrintAria";
import moment from 'moment'
import orderView from "./orderView";

export default {
    name: "OrdersDetails",
    mixins: [FormHelperMixins, orderView],
    extends: CoreLibrary,
    props: {
        orderId: {},
    },
    data() {
        return {
            rowData: {},
            checkedItems: {}, // Object to store checkbox statuses (item.id as keys)
            tableId: '',
            value: '',
            ucWords,
            purify,
            invoiceTemplateToPrint: '',
            printAria: false,
            showNote: true,
            loading: false,
            DUE_PAYMENT_RECEIVE,
            DUE_PAYMENT_INFO,
            SAVE_ORDER_DATA,
            invoice: {},
            ordersCreateMod:this.$can('orders_create_mod'),
            ordersEditDiscount:this.$can('orders_edit_discount'),
            ordersAddEditItem:this.$can('orders_add_edit_item'),
            ordersAddEditTechnician:this.$can('orders_add_edit_technician'),
            ordersAddEditCustomization:this.$can('orders_add_edit_customization'),
            ordersEdit:this.$can('orders_edit'),
            ordersAddEditDeliveryDetails:this.$can('orders_add_edit_delivery_details'),
            paymentsAddEditReversed:this.$can('payments_add_edit_reversed'),
            paymentsAddEdit:this.$can('payments_add_edit'),
            cpPaymentsEdit:this.$can('cp_payments_edit'),
            cpPaymentsView:this.$can('cp_payments_view'),
            isCustomGarmentsModalActive: false,
            isCustomFootwareModalActive: false,
            isCustomOrthosesModalActive: false,
            isCustomProstheticsModalActive: false,
            isCustomInsolesModalActive: false,
            isOrdersModalActive: false,
            isCustomOrdersModalActive: false,
            customOrdersUrl: '',
            invoiceData: {
                logo_source: 'https://media.glassdoor.com/sqll/4308684/gain-solutions-squarelogo-1608634285110.png',
                company_address: 'Nirala',
                company_phone: '01717605715',
                company_email: 'shishir@gain.media',
                cash_counter: '1'
            },
            extraFormData:{
                technicianOneId: 0,
                technicianTwoId: 0,
                qualityCheckId: 0,
                targetDate: '',
                deliveryMode: '',
                readyDate: '',
                patientInformed: '',
                pickupDate: '',
                pickupPerson: '',
                dispatchDate: '',
                trackingInfo: '',
                customer_items_retained: 'None',
                lockerin: '',
                lockerout: '',
                workshop_note: '',
                referralStatus: 'NA',
                referralNote: '',
                workshop_images: [],
            },
            workshopfiles: {
                file1: null,
                file2: null,
                file3: null,
                file4: null,
                file5: null,
                file6: null,
                file7: null,
                file8: null,
                file1Url: null,
                file2Url: null,
                file3Url: null,
                file4Url: null,
                file5Url: null,
                file6Url: null,
                file7Url: null,
                file8Url: null,
                file1Url: null,
            },
            workshopName: '',
            move_to_warehouse: false,
            formData: {},
            ShoeType: [
                { id: '', name: this.$t('Select Shoe Type') },
                { id: 'D 1', name: this.$t('D 1') },
                { id: 'D 2', name: this.$t('D 2') },
                { id: 'D 3', name: this.$t('D 3') },
            ],
            deliveryModeOptions: [
                { id: '', name: this.$t('Select Delivery Mode') },
                { id: 'pickup', name: this.$t('Pickup') },
                { id: 'delivery', name: this.$t('Delivery') },
            ],
            MedialWall: [
                { id: '', name: this.$t('Select Shoe Type') },
                { id: 'Normal', name: this.$t('Normal') },
                { id: 'High', name: this.$t('High') },
            ],
            Delivery: [
                { id: '', name: this.$t('Select Shoe Type') },
                { id: 'In trial', name: this.$t('In trial') },
                { id: 'Finished', name: this.$t('Finished') },
            ],
            Mould: [
                { id: '', name: this.$t('Select Mould') },
                { id: 'POP Cast', name: this.$t('POP Cast') },
                { id: 'Silicon Bag', name: this.$t('Silicon Bag') },
                { id: 'Foam Box', name: this.$t('Foam Box') },
                { id: '3D Scan', name: this.$t('3D Scan') },
                { id: 'On foot', name: this.$t('On foot') },
                { id: 'Sidas last', name: this.$t('Sidas last') },
                { id: 'Mid shell', name: this.$t('Mid shell') },
                { id: 'High shell', name: this.$t('High shell') },
            ],
            referralOptions: [
                { id: 'NA', name: this.$t('NA') },
                { id: 'No', name: this.$t('No') },
                { id: 'Yes', name: this.$t('Yes') },
            ],
            technicianInputKey: 1,
            technicianOptions: [],
            // technicianOptions: {
            //     url: urlGenerator(SELECTABLE_TECHNICIAN),
            //     query_name: "search_by_name",
            //     per_page: 10,
            //     loader: "app-pre-loader", // by default 'app-overlay-loader'
            //     modifire: (value) => ({id: value.id, value: value.technician_name}),
            //     prefetch: true
            // },
            qualityCheckOptions: [],
            selectedOrderProductId: null,
            selectedOrderId: null,
            todayDate: new Date(),
            isOrdersActionModalActive: false,
            isTechnicianActionModalActive: false,
            isDeliveryActionModalActive: false,
            isPaymentActionModalActive: false,
            isDiscountActionModalActive: false,
            isModOrderActionModalActive: false,
            isCPpaymentStatusActionModalActive: false,
            paymentId: 0,
            iteminfo: [],
            techinfo: [],
        }
    },
    computed: {
        // isDisabled() {
        //     // Check if the item's warehouse_status_id is 63, and if so, disable the checkbox
        //     return (item) => item.warehouse_status_id === 63;
        // },
    },
    methods: {
        getAttachmentFileName(filename) {
            let nameArr = filename.split('/');
            return nameArr[nameArr.length-1];
        },
        getSubTotal(order) {
            var price = 0;
            if(order.order_products != undefined) {
                for (let item of order.order_products) {
                    price += item.price;
                }
            }
            return price;
        },
        getTotalTax(order) {
            var price = 0;
            if(order.order_products != undefined) {
                for (let item of order.order_products) {
                    if(item.tax_amount > 0) {
                        let taxAmt = item.sub_total - (item.sub_total/((100+item.tax_amount)/100));
                        price += taxAmt;
                    }
                }
            }
            return price;
        },
        getDiscountValue(order) {
            var price = 0;
            if(order.order_products != undefined) {
                if(order.discount_value == 0) {
                    for (let item of order.order_products) {
                        price += item.discount_amount;
                    }
                } else {
                    price = order.discount_value
                }
            }
            return price;
        },
        convertToLetter(index) {
            let letters = '';
            while (index >= 0) {
                letters = String.fromCharCode((index % 26) + 97) + letters;
                index = Math.floor(index / 26) - 1;
            }
            return letters;
        },
        deleteTechnician(techid) {
            axiosGet(`${DELETE_ORDER_TECHNICIAN}/${techid}`)
                .then(response => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                    this.toastAndReload(response.data.msg);
                }).catch(error => {
                    console.error(error);
                });
        },
        formatDate(dateObj) {
            if(dateObj != '' && dateObj != '0000-00-00') {
                dateObj = new Date(dateObj);
                const date = {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate()}
                return `${date.year}-${date.month <= 9 ? '0' : ''}${date.month}-${date.day <= 9 ? '0' : ''}${date.day}`;
            }
            return '';
        },
        getQualityCheckOptions() {
            try {
                axiosGet(`${SELECTABLE_DROPDOWN}?list_id=15`).then(response => {
                    let i = 0;
                    for (let item of response.data.data) {
                        if(i == 0) {
                            this.qualityCheckOptions.push({
                                id: 0,
                                value: 'None',
                            });    
                        }
                        this.qualityCheckOptions.push({
                            id: item.id,
                            value: item.item_label,
                        });
                        i++;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        technicianListOptions() {
            axiosGet(SELECTABLE_TECHNICIAN).then(response => {
                this.technicianOptions.push({
                    id: 0,
                    value: 'None',
                });
                for (const item of response.data.data) {
                    this.technicianOptions.push({
                        id: item.id,
                        value: item.technician_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        isChecked(value) {
            // console.log(`ID: ${value.warehouse_status_id}`);

            if (value.warehouse_status_id === 63) {
                this.checkedItems[value.id] = true;
            }

            // Check if the checkbox should be checked based on the item's warehouse_status_id
            // return (item) => item.warehouse_status_id === 63;
        },
        handleImagesUpload(section, imageProperty) {
            const fileInput = this.$refs[`${section}-${imageProperty}`];
            const allowedFileTypes = ['image/jpeg', 'image/gif', 'application/cdr', 'application/dxf', 'image/svg+xml', 'application/pdf', 'application/stl', 'application/cnc', 'application/imed', 'image/png', 'image/vnd.adobe.photoshop', 'text/plain'];
            const imagePropertyUrl = imageProperty + 'Url';

            // Check if fileInput exists and has files
            if (fileInput && fileInput.files.length > 0) {
                const imageFile = fileInput.files[0];

                // Check if the selected file type is allowed
                if (!allowedFileTypes.includes(imageFile.type)) {
                this[section][imageProperty] = null; // Clear the invalid image property
                this[section][imagePropertyUrl] = `The selected file for "${imageProperty}" is not an image (jpg, jpeg, gif, cdr, dxf, pdf, stl, cnc, imed, png, psd, txt).`;
                return; // Stop further processing
                }

                this.fileValidationError = null;

                // Set the image property and its URL dynamically
                this[section][imageProperty] = imageFile;
                console.log('F', this[section][imageProperty]);

                // Generate the image URL for display
                this[section][`${imageProperty}Url`] = URL.createObjectURL(imageFile);
            } else {
                // Clear the image property and URL if no file is selected
                this[section][imageProperty] = null;
                this[section][`${imageProperty}Url`] = null;
                this.fileValidationError = null;
            }
        },
        deleteImage(imagename) {
            this.workshopfiles[imagename] = 'delete';
        },
        handleCheckboxChange(itemId) {
            const endpointUrl = `${CHANGE_WAREHOUSE_STATUS}/${itemId}`;

            // Use the checkbox status for the specific item (item.order_id)
            const newWarehouseStatusId = this.checkedItems[itemId] ? 63 : 0;  // Toggle between 63 and 0

            const WarehouseId = this.invoice.branch_or_warehouse.workshop;
            // console.log(`Warehouse ID: ${WarehouseId}`);
            // console.log(`newWarehouseStatus ID: ${newWarehouseStatusId}`);

            // Prepare the data to send in the request body
            const requestData = {
                warehouse_id: WarehouseId,
                warehouse_status_id: newWarehouseStatusId,
            };

            axiosPost(endpointUrl, requestData)
                .then(response => {
                    // console.log(response.data);
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                    this.toastAndReload(response.data.message);
                }).catch(error => {
                    console.error(error);
                    // Handle any errors, if needed
                });
        },
        triggerAction(row, action, active) {
            this.orderId = row.id;
            if (action.type === 'view') {
                this.isInvoiceModalActive = true;
            } else if (action.type === 'due_receive') {
                this.isModalActive = true;
            } else if (action.type === 'custom_insoles_customization') {
                // this.isOrdersModalActive = true;
                this.customOrdersUrl = `${CUSTOM_GARMENTS_CUSTOMIZATION}/${row.id}`;
                this.isCustomOrdersModalActive = true;
            }
        },
        shouldDisplayCustomOrders(item) {
            // Check if the category ID is one of the specified values
            const allowedCategoryIds = [1, 2, 3, 4, 5];
            return item.variant.product.main_category == 3 && allowedCategoryIds.includes(item.variant.product.category_id);
        },
        openModal(data) {
            const categoryId = data.variant.product.category_id;
            const orderProductId = data.id;
            const orderId = data.order_id;
            // console.log('C', data);
            // Determine the product category and set the corresponding modal property to true
            if (categoryId === 1) {
                this.isCustomOrthosesModalActive = true;
                this.selectedOrderProductId = orderProductId;
                this.selectedOrderId = orderId;
            } else if (categoryId === 2) {
                // console.log(categoryId);
                this.isCustomInsolesModalActive = true;
                this.selectedOrderProductId = orderProductId;
                this.selectedOrderId = orderId;
            }
            else if (categoryId === 3) {
                // console.log(categoryId);
                this.isCustomFootwareModalActive = true;
                this.selectedOrderProductId = orderProductId;
                this.selectedOrderId = orderId;
            }
            else if (categoryId === 4) {
                // console.log(categoryId);
                this.isCustomProstheticsModalActive = true;
                this.selectedOrderProductId = orderProductId;
                this.selectedOrderId = orderId;
            }
            else if (categoryId === 5) {
                // console.log(categoryId);
                // if (data.custom_garments_customization) {
                //     this.customOrdersUrl = `${CUSTOM_GARMENTS_CUSTOMIZATION}/${orderProductId}`;
                // } else {
                //     // Set the customOrdersUrl to a default value or null if there's no data
                // }
                this.customOrdersUrl = null; // or some default URL

                this.isCustomGarmentsModalActive = true;
                this.selectedOrderProductId = orderProductId;
                this.selectedOrderId = orderId;
            }
        },
        numberWithCurrencySymbol,
        formatDateTimeToLocal,
        formatDateToLocal,
        handlePrintAreaCloseEvent() {
            this.printAria = false;
        },
        prepareInvoice() {
            for (const key in this.invoiceData) {
                this.assainValue(key, this.invoiceData[key])
            }
        },
        assainValue(key, value) {
            this.invoiceTemplateToPrint = this.invoiceTemplateToPrint.replace(`{${key}}`, value)
        },
        saveCustomInsolesCustomization() {
            this.formData.qty = this.invoice.order_products[0].quantity;
            this.formData.order_id = this.orderId;

            const customizationId = this.formData.id;
            // console.log(customizationId);

            // Perform insert or update based on whether postureDataId exists
            if (customizationId) {
                this.updateCustomInsolesCustomization(customizationId, this.formData);
            } else {
                this.insertCustomInsolesCustomization(this.formData);
            }
        },
        insertCustomInsolesCustomization(formData) {
            axiosPost(`${CUSTOM_INSOLES_CUSTOMIZATION}`, formData)
                .then(response => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                    this.toastAndReload(response.data.message);
                    // Do something after successful data update, if needed
                })
                .catch(error => {
                    console.error(error);
                    // Handle any errors, if needed
                });
        },
        updateCustomInsolesCustomization(customizationId, formData) {
            axiosPatch(`${CUSTOM_INSOLES_CUSTOMIZATION}/${customizationId}`, formData)
                .then(response => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                    this.toastAndReload(response.data.message);
                    // Do something after successful data update, if needed
                })
                .catch(error => {
                    console.error(error);
                    // Handle any errors, if needed
                });
        },
        getCustomInsolesCustomization() {
            axiosGet(`${CUSTOM_INSOLES_CUSTOMIZATION}/${this.orderId}`).then(response => {
                const data = response.data[0];
                // console.log(data.shoe_brand);
                this.formData = data;
            });
        },
        getOrderInformation() {
            axiosGet(`${INVOICE_VIEW_ORDER}/${this.orderId}`).then(response => {
                this.invoice = response.data
                this.rowData = this.invoice.order_products

                var workshopNameTemp = '';
                this.rowData.some(function(item) {
                    if(item.stock_deduction_type == 2 && item.branch_or_warehouse.workshop != null) {
                        workshopNameTemp = item.branch_or_warehouse?.workshop?.name;
                    } else {
                        workshopNameTemp = item.branch_or_warehouse.name;
                    }
                    return item.stock_deduction_type === 2;
                });
                this.workshopName = workshopNameTemp;

                var hideDiscountBtn = false;
                this.rowData.some(function(item) {
                    if(response.data.order_products[0].tax_amount != item.tax_amount) {
                        hideDiscountBtn = true;
                        return true; 
                    }
                });

                if(hideDiscountBtn) this.ordersEditDiscount = false;
                

                this.extraFormData.technicianOneId = (!Array.isArray(this.invoice.technician_one))?this.invoice.technician_one?.id:this.invoice.technician_one;
                this.extraFormData.technicianTwoId = (!Array.isArray(this.invoice.technician_two))?this.invoice.technician_two?.id:this.invoice.technician_two;
                this.extraFormData.qualityCheckId = (!Array.isArray(this.invoice.quality_check))?this.invoice.quality_check?.id:this.invoice.quality_check;

                if(typeof this.extraFormData.technicianOneId == 'undefined') this.extraFormData.technicianOneId = 0; 
                if(typeof this.extraFormData.technicianTwoId == 'undefined') this.extraFormData.technicianTwoId = 0; 
                if(typeof this.extraFormData.qualityCheckId == 'undefined') this.extraFormData.qualityCheckId = 0; 
                this.extraFormData.targetDate = (this.invoice.target_date == '0000-00-00')?'':this.invoice.target_date;
                this.extraFormData.deliveryMode = (this.invoice.delivery_mode == '0000-00-00')?'':this.invoice.delivery_mode;
                this.extraFormData.readyDate = (this.invoice.ready_date == '0000-00-00')?'':this.invoice.ready_date;
                this.extraFormData.patientInformed = this.invoice.patient_informed;
                this.extraFormData.pickupDate = this.invoice.pickup_date;
                this.extraFormData.pickupPerson = this.invoice.pickup_person;
                this.extraFormData.dispatchDate = (this.invoice.dispatch_date == '0000-00-00')?'':this.invoice.dispatch_date;
                this.extraFormData.trackingInfo = this.invoice.tracking_info;
                this.extraFormData.customer_items_retained = this.invoice.customer_items_retained??'None';
                this.extraFormData.lockerin = this.invoice.lockerin;
                this.extraFormData.lockerout = this.invoice.lockerout;
                this.extraFormData.workshop_note = this.invoice.workshop_note;
                this.extraFormData.referralStatus = this.invoice.ref_doctor_status;
                this.extraFormData.referralNote = this.invoice.ref_doctor_note;
                this.workshopfiles.file1 = this.invoice.file1;
                this.workshopfiles.file2 = this.invoice.file2;
                this.workshopfiles.file3 = this.invoice.file3;
                this.workshopfiles.file4 = this.invoice.file4;
                this.workshopfiles.file5 = this.invoice.file5;
                this.workshopfiles.file6 = this.invoice.file6;
                this.workshopfiles.file7 = this.invoice.file7;
                this.workshopfiles.file8 = this.invoice.file8;
            })
        },
        submitData() {
            const self = this;
            this.extraFormData.orderId = this.orderId;
            const workshopfileData = new FormData();
            Object.entries(this.extraFormData).forEach(function([key, value]) {
                if(['targetDate','readyDate','pickupDate','dispatchDate'].includes(key) && value != null) {
                    value = self.formatDate(value);
                }
                workshopfileData.append(key, value);
            });
            Object.entries(this.workshopfiles).forEach(function([key, value]) {
                workshopfileData.append(key, value);
            });
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};
            
            axiosPost(`${SAVE_ORDER_DATA}`, workshopfileData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(response => {
                this.toastAndReload(response.data.message);
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }).catch(error => {
                this.$toastr.e(error.response.data.message);
            });
        },
        afterSuccess({ data }) {
            // this.extraFormData = {};
            // this.preloader = false;
            // this.toastAndReload(data.message);
            // setTimeout(() => {
            //     // window.location.reload()
            // }, 1500)
        },
        triggerAction(row, action, active) {
            // this.orderId = row.id;
            // if (action.type === 'view') {
            //     this.isInvoiceModalActive = true;
            // } else if (action.type === 'due_receive') {
            //     this.isModalActive = true;
            // } else if (action.type === 'custom_insoles_customization') {
            //     this.isOrdersActionModalActive = true;
            // }
        },
        closeModal() {
            this.isOrdersActionModalActive = false;
            this.isTechnicianActionModalActive = false;
            this.isDeliveryActionModalActive = false;
            this.isModOrderActionModalActive = false;
            this.isPaymentActionModalActive = false;
            this.isDiscountActionModalActive = false;
            this.isCPpaymentStatusActionModalActive = false;
            this.$emit('modal-close');
        },
        openOrderActionModal(iteminfo = []) {
            this.isOrdersActionModalActive = true;
            this.iteminfo = iteminfo;
            // this.selectedUrl = ''
        },
        openTechnicianActionModal(iteminfo = [],techinfo = [],itemIdx = null) {
            this.isTechnicianActionModalActive = true;
            this.iteminfo = iteminfo;
            this.techinfo = techinfo;
            this.itemIdx = itemIdx;
            // this.selectedUrl = ''
        },
        openDeliveryActionModal(iteminfo = []) {
            this.isDeliveryActionModalActive = true;
            this.iteminfo = iteminfo;
            // this.selectedUrl = ''
        },
        openCPpaymentStatusActionModal(iteminfo = []) {
            this.isCPpaymentStatusActionModalActive = true;
            // this.iteminfo = iteminfo;
            // this.selectedUrl = ''
        },
        openPaymentActionModal(paymentId) {
            this.paymentId = paymentId;
            this.isPaymentActionModalActive = true;
            // this.iteminfo = iteminfo;
            // this.selectedUrl = ''
        },
        openDiscountActionModal(iteminfo = []) {
            this.isDiscountActionModalActive = true;
            this.iteminfo = iteminfo;
        },
        openOrderModActionModal(iteminfo = []) {
            this.isModOrderActionModalActive = true;
            this.iteminfo = iteminfo;
        }
    },
    created() {
        this.getOrderInformation()
        this.getQualityCheckOptions()
        this.technicianListOptions()
        this.getCustomInsolesCustomization()
    }
}
</script>
<style lang="scss" scoped>
.filters-wrapper .single-filter {
    display: none;
}
</style>