<template>
    <div class="row justify-content-center py-5 max-height-450 overflow-auto">
        <div class="col-11 col-lg-10">
            <div class="d-flex mb-primary">
                <table width="100%" class="expandTblClass">
                    <thead>
                        <tr>
                            <th>Practitioner</th>
                            <th>Total # Patients</th>
                            <th>Total # Orders</th>
                            <th>Total Completed</th>
                            <th>Order Value</th>
                            <th>Payment Received</th>
                            <th>Payment Due</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in orders" :key="index">
                            <td class="datatable-td">{{ item.created_by.full_name }}</td>
                            <td class="datatable-td">{{ item.all_customers_count }}</td>
                            <td class="datatable-td">{{ item.order_count }}</td>
                            <td class="datatable-td">{{ item.order_completed_count }}</td>
                            <td class="datatable-td">{{ numberWithCurrencySymbol(item.order_value) }}</td>
                            <td class="datatable-td">{{ numberWithCurrencySymbol(item.payment_received) }}</td>
                            <td class="datatable-td">{{ numberWithCurrencySymbol(item.payment_due) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {numberWithCurrencySymbol} from "../../../../Helper/Helper";
import {PRACTITIONER_SUMMARY_REPORT} from "../../../../Config/ApiUrl-CP";
import {axiosGet,urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name: "PractitionerReport",
    mixins: [FormHelperMixins],
    props: ['data'],
    data() {
        return {
            numberWithCurrencySymbol,
            orders:[],
        }
    },
    created(){
        this.getPractitionerData();
    },
    methods: {
        getPractitionerData() {
            axiosGet(`${PRACTITIONER_SUMMARY_REPORT}/${this.data.branch_or_warehouse_id}`).then(response => {
                this.orders = response.data;
            })
        }
    }
}
</script>
<style>
.datatable table .table-expanded-column button svg{
    display: none;
}
.datatable table.expandTblClass tbody tr td.datatable-td {
 padding: 1.5rem 1rem !important;
}
</style>