<template>
    <div class="row justify-content-center py-5 max-height-450 overflow-auto">
        <div class="col-11 col-lg-10">
            <div class="d-flex mb-primary">
                <table width="100%" class="expandTblClass">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>items</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data.order_products" :key="index">
                            <td class="datatable-td">{{ index+1 }}</td>
                            <td class="datatable-td">
                                <a> {{ item.variant.name }} </a> 
                                <small class="d-block text-muted">{{ item.variant.upc }}</small>
                            </td>
                            <td class="datatable-td">{{ item.variant.product.category?.name??'--' }}</td>
                            <td class="datatable-td">{{ item.variant.product.sub_category?.name??'--' }}</td>
                            <td class="datatable-td">{{ numberWithCurrencySymbol(item.price) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {numberWithCurrencySymbol} from "../../../../Helper/Helper";

export default {
    name: "salesProductDetails",
    mixins: [FormHelperMixins],
    props: ['data'],
    data() {
        return {
            numberWithCurrencySymbol
            
        }
    }
}
</script>
<style>
.datatable table .table-expanded-column button svg{
    display: none;
}
.datatable table.expandTblClass tbody tr td.datatable-td {
 padding: 1.5rem 1rem !important;
}
</style>