import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {
    PRODUCTS, SELECTABLE_GROUPS,
    SELECTABLE_BRANDS, SELECTABLE_ATTRIBUTES,
    SELECTABLE_UNITS, SELECTABLE_CATEGORIES, SELECTABLE_SUB_CATEGORIES,VARIANTS
} from "../../Config/ApiUrl-CP";
import {createdBy} from "../../../store/Tenant/Mixins/CreatedByMixin";
import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import SelectableStatusMixin from "../../Helper/SelectableOptions/SelectableStatusMixin";
import {numberWithCurrencySymbol} from "../../Helper/Helper";
import { mapGetters } from "vuex";

export default {
    mixins: [DatatableHelperMixin, createdBy, SelectableStatusMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: VARIANTS,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                enableRowSelect: false,
                sortByFilter: {
                    isVisible: false,
                    label: this.$t("sort_by") + ':',
                    key: 'sort_by',
                    options: [
                        this.$t('most_recent'),
                        this.$t('product_name_a_z'),
                        this.$t('product_name_z_a'),
                        this.$t('last_created')
                    ]
                },
                columns: [
                    {
                        title: this.$t('Product Name'),
                        type: 'component',
                        key: 'name',
                        componentName: 'products-profile',
                    },
                    {
                        title: this.$t('Main Category'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            let mainCategoryArr = {
                                1:'Raw Material',
                                2:'Ready to use',
                                3:'Custom Products',
                                4:'Service',
                            }
                            if(value) {
                                return `<span>${mainCategoryArr[value.main_category]}</span>`;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: this.$t('Category'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.category) {
                                return `<span>${value.category.name}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Sub Category'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.sub_category) {
                                return `<span>${value.sub_category.name}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Brand'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.brand) {
                                return `<span>${value.brand.name}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Gender'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.gender) {
                                return `<span>${value.gender.item_label}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Age Group'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.age_group) {
                                return `<span>${value.age_group.item_label}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Unit'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.unit) {
                                return `<span>${value.unit.name}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Gst %'),
                        type: 'custom-html',
                        key: 'tax',
                        modifier: (value) => {
                            if(value && value.percentage) {
                                return `<span>${value.percentage}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Net Price'),
                        type: 'custom-html',
                        key: 'base_price',
                        modifier: (value,row) => {
                            if(value) {
                                if(this.isPartner && row.cp_prices) {
                                    return `<span>${numberWithCurrencySymbol(row.cp_prices.cp_base_price)}</span>`;
                                } else {
                                    return `<span>${numberWithCurrencySymbol(value)}</span>`;
                                }
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Gst Amount'),
                        type: 'custom-html',
                        key: 'tax_amount',
                        modifier: (value,row) => {
                            if(value) {
                                if(this.isPartner && row.cp_prices) {
                                    return `<span>${numberWithCurrencySymbol(row.cp_prices.cp_gst)}</span>`;
                                } else {
                                    return `<span>${numberWithCurrencySymbol(value)}</span>`;
                                }
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Selling Price'),
                        type: 'custom-html',
                        key: 'selling_price',
                        modifier: (value,row) => {
                            if(value) {
                                if(this.isPartner && row.cp_prices) {
                                    return `<span>${numberWithCurrencySymbol(row.cp_prices.cp_total_price)}</span>`;
                                } else {
                                    return `<span>${numberWithCurrencySymbol(value)}</span>`;
                                }
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Min Qty'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.low_threshold) {
                                return `<span>${value.low_threshold}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Max Qty'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.high_threshold) {
                                return `<span>${value.high_threshold}</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('Warranty'),
                        type: 'custom-html',
                        key: 'product',
                        modifier: (value) => {
                            if(value && value.warranty_duration) {
                                return `<span>${value.warranty_duration+" "+value.duration.type }</span>`;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'actions',
                    },
                ],
                actionType: "dropdown",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'edit',
                        name: 'edit',
                        modifier: () => this.$can('update_products')
                    },
                    {
                        title: this.$t('show_stock_overview'),
                        name: 'show_stock_overview',
                        component: '',
                        modifier: row => {
                            return row.product.product_type.toLowerCase() === 'single'
                        },
                    },
                    {
                        title: this.$t('Edit Cp Price'),
                        type: 'modal',
                        name: 'cpprice_action',
                        component: 'app-cpprice-action-modal',
                        modalId: 'app-modal',
                        modifier: () => this.isPartner
                    },
                    
                    // {
                    //     title: this.$t('delete'),
                    //     icon: 'trash-2',
                    //     type: 'modal',
                    //     name: 'delete',
                    //     url: PRODUCTS,
                    //     component: 'app-confirmation-modal',
                    //     modalId: 'app-confirmation-modal',
                    //     modifier: () => this.$can('delete_products')
                    // },

                ],
                filters: [
                    {
                        title: this.$t('Main Category'),
                        type: 'drop-down-filter',
                        key: 'main_category',
                        option: [
                            { id: 1, value: this.$t('Raw Material') },
                            { id: 2, value: this.$t('Ready to use') },
                            { id: 3, value: this.$t('Custom Products') },
                            { id: 4, value: this.$t('Service') },
                        ]
                    },
                    {
                        title: this.$t('category'),
                        type: 'search-and-select-filter',
                        key: 'categories',
                        settings: {
                            url: urlGenerator('app/selectable-categories'),
                            modifire: ({id, name}) => ({id, name}),
                            params: {},
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'name'
                    },
                    {
                        title: this.$t('Sub Category'),
                        type: 'search-and-select-filter',
                        key: 'subcategory',
                        settings: {
                            url: urlGenerator('app/selectable-sub-categories'),
                            modifire: ({id, name}) => ({id, name}),
                            params: {},
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'name'
                    },
                    // "units" filter
                    {
                        title: this.$t('units'),
                        type: 'search-and-select-filter',
                        key: 'units',
                        settings: {
                            url: urlGenerator('app/selectable-units'),
                            modifire: ({id, name}) => ({id, name}),
                            params: {},
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'name'
                    },
                    {
                        title: this.$t('Gender'),
                        type: 'search-and-select-filter',
                        key: 'gender',
                        settings: {
                            url: urlGenerator('app/getDropDownLists'),
                            modifire: ({id, item_label}) => ({id, item_label}),
                            params: {list_id:12},
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'item_label'
                    },
                    {
                        title: this.$t('Age Group'),
                        type: 'search-and-select-filter',
                        key: 'ageGroup',
                        settings: {
                            url: urlGenerator('app/getDropDownLists'),
                            modifire: ({id, item_label}) => ({id, item_label}),
                            params: {list_id:13},
                            per_page: 10,
                            queryName: 'search_by_name',
                            loader: 'app-pre-loader'
                        },
                        listValueField: 'item_label'
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                search: true,
            },
        }
    },
    mounted() {
        this.$store.dispatch('getTag');
    },
    methods: {
        filterInitiate(urls) {
            for (const [key, url] of Object.entries(urls)) {
                axiosGet(url).then(response => {
                    let name = this.options.filters.find(element => element.key === key);
                    name.option = response.data.data.map(name => {
                        return {
                            id: name.id,
                            name: name.name
                        }
                    });
                })
            }
        },
        updateUrl() {
            this.options.url = `${VARIANTS}?isPartner=${this.isPartner}&branch_or_warehouse_id=${this.getBranchOrWarehouseId}`
            this.$hub.$emit(`reload-${this.table_id}`)
        }
    },
    computed: {
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    watch: {
        getBranchOrWarehouseInfo(val) {
            this.updateUrl();
        }
    }
}
